.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .spin {
    animation: spin infinite 1.5s linear, moveRight infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  height: 100vh;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes moveRight {
  0% {
    left: 4vw;
  }

  50% {
    left: 93vw;
  }

  100% {
    left: 4vw;
  }
}

* {
  box-sizing: border-box;
}

.img-magnifier-container {
  position: relative;
}

.img-magnifier-glass {
  position: absolute;
  visibility: hidden;
  border: 3px solid #000;
  border-radius: 50%;
  cursor: none;
  /*Set the size of the magnifier glass:*/
  width: 300px;
  height: 300px;
}

#image {
  overflow: hidden;
}

#image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all .3s ease;
}

#image:hover {
  transform: scale(1.1);
}

#affTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 50%;
  margin-top: 25px !important;
}

#affTable td,
#affTable th {
  border: 1px solid black;
  padding: 8px;
}

#affTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #FECA1C;
  color: black;
}

.marterAlerts {
  animation: beamEffect 2s infinite ease-in-out;
  border: 3px solid rgba(0, 0, 0, 0.7);
}

.marterAlerts:hover {
  transform: translateX(-1vw) !important;
  width: 20vw !important;
}

@keyframes beamEffect {

  0%,
  100% {
    box-shadow: 0 0 15px 5px rgba(245, 203, 50, 0.6);
    transform: translateY(-5px);
  }

  50% {
    box-shadow: 0 0 30px 15px rgba(255, 225, 0, 0.8);
    transform: translateY(5px);
  }
}

.spinningIcon {
  animation: spinningIconEffect 2s infinite;
}

@keyframes spinningIconEffect {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    background-color: #0077b6;
  }

  50% {
    transform: scale(1.05);
    background-color: #0096c7;
  }

  100% {
    transform: scale(1);
    background-color: #0077b6;
  }
}

.alertImgTable:hover {
  transform: scale(3) translateX(5px) translateY(-5px);
  z-index: 999999999 !important;
}
